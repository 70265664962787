import React from "react"
import Frame from "../components/frame"
import style from "./style/projects.module.css"

export const Project = (props) => {

    const mapLinkKeyValue = (kv) => {
        return (
        <p style={{fontSize: '0.9em', }}>
            <a href={kv[0]}>{kv[1]}</a>
        </p>)
    }

    return (<div>
        <h3 className={`title-small`}>{props.title}</h3>
        <p>{props.children}</p>
        <div>
            {props.links.map(mapLinkKeyValue)}
        </div>
    </div>)
}

export default () => {
    return (
        <Frame>
            <div className={`sectionbox`}>
                {/*
                    <h3 className={`text-sans-serif-display text-bold col-fg`}>
                    <span role="img" aria-label="Eyes emoji glancing left">👀</span> Nothing to see here... yet!
                </h3>
                <p className={`text-sans-serif-display col-fg-light`} style={{color: 'var(--fg-light)'}}>Check back soon to see an updated version of this page.</p>
                */}

                <h2 className={`text-sans-serif-display text-bold col-fg`}>Projects</h2>

                <Project title="LabMojo" links={[
                    ['https://www.labmojo.com/', 'LabMojo']
                ]}>
                    I am currently the lead technical engineer at LabMojo, a startup dedicated to helping students find research opportunities in university settings that align with their interests. So far, I have made some UI/UX improvements, but I'm currently strategizing pipelines for data collection, remediation, and refinement as well as further improvements and features. 
                </Project>

                <Project title="COVID-19 Risk Assessor" links={[
                    ['https://vast-atoll-45273.herokuapp.com', 'Check it out']
                ]}>
                    In the fall of 2020, our team was tasked with creating a website. We decided to create a web app that determined a rough estimate of the level of risk of contracting COVID-19 that people faced by going to social gatherings and events. I was responsible for configuring the tech stack, implementing authorization, data handling and storage, creating a data visualization, and designing the risk calculation algorithm.
                </Project>

                <Project title="NC State Datathon" links={[
                    ['https://ncstate-datathon2021.herokuapp.com/', 'See it in action'],
                    ['https://sciences.ncsu.edu/news/virtual-datathon-draws-more-than-100-students-from-universities-across-n-c/', 'Article about competition']
                ]}>
                    In the spring of 2021, a team of two undergraduates and I were tasked with analyzing a crime dataset from the Raleigh NC police department. During this analysis, I created a simple data visualization to view a heatmap by year and category in under 24 hours, and put it on the web.
                </Project>
            </div>
            
        </Frame>
    )
}